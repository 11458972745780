<template>
    <div class="image-slider-container">
        <div class='container'>
            <div class='img background-img' ></div>
            <div class='img foreground-img' :style="sliderWidth"></div>
            <div class="label-container">
                <input type="range" min="1" max="100"  class="slider"  v-model="sliderValue2">
            </div>
            <div class="container-label label-left">Before</div>
            <div class="container-label label-right">After</div>
        </div>
        <div class="range">
            <input  type="range" min="1" max="100"  class="slider image-slider" id="myRange" v-model="sliderValue"> 
        </div>
        <div class="slider-label">SLIDE TO COMPARE BEFORE & AFTER</div>
    </div>
    
</template>

<script>
export default {
    props: [ 'imageBefore', 'imageAfter'],
    data() {
        return {
            sliderValue: 50,
            sliderWidth: {width: '50%'},
            rangeLeft: {left: '50%'},
			afterImageURL: `..assets/images/${this.imageAfter}`,
			afterImage:  {backgroundImage: this.afterImageURL} ,
			testStyle: {backgroundImage: "URL('../assets/images/BioMap.jpg')"}         
        }
    },
    methods: {
    },
    mounted() {
        
    },
    watch: {
        sliderValue: function() {
            this.sliderWidth={width: `${this.sliderValue}%`}
            this.rangeLeft={left: `${this.sliderValue}%`}
            this.sliderValue2 = this.sliderValue
        },
    }
}

</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

$width: 250px;
$height: calc($width/3 * 2);

.container {
  position: relative;
  width: $width;
  height: $height;
  border: 2px solid white;
  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size:cover;
  }
  .background-img {
    background-image: url('../assets/images/2SecretRF-Sarti-MD-Before.jpg');
  }
  .foreground-img {
    background-image: url('../assets/images/2SecretRF-Sarti-MD-After.jpg');
    width: 50%;
  }
  @mixin center() {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container-label {
    display: inline-block;
    position: absolute;
    bottom: 0;
    background-color: black;
    padding: 3px 10px;
    color: white;
}
.label-right {
    right: 0;
}

.slider-label {
    text-align: center;
    width: $width;
    font-weight: 500;
    color: $primary;
    font-size: 11px;
}
.image-slider-container {
    border: 1px solid $primary-light-grey;
    width: calc($width + 4px);
    position: relative;
    left: 0;
	margin: 10px;
	display: inline-block;
}

$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
$color-range-output: #383c42;
$color-input-placeholder: #c3c4c6;
$height-range-bounds: 22px;
$background-color-range-thumb: #333940;
$radius-range-thumb: 100px;
$height-range-thumb: 24px;
$width-range-thumb: 24px;
$size-range-thumb-shadow: 25px;
$color-range-thumb-shadow: rgba(#000, .5);
$shadow-offset-y-range-thumb: 4px;
$shadow-offset-x-range-thumb: 0px;
$border-width-range-thumb: 6px;
$border-color-range-thumb: #fff;
$width-range-track: 100%;
$height-range-track: 4px;
$radius-range-track: 100px;
$fill-range-track: #e6e6e6;
$fill-range-track-active: #60cd18;

//Output
$height-range-output: 60px;
$min-width-range-output: 32px;
$padding-x-range-output: 20px;
$size-range-output-arrow: 10px;
$offset-y-range-output: 4px;

.range {
	@mixin range-track() {
		box-sizing: border-box;
		width: $width-range-track;
		height: $height-range-track;
		background: $fill-range-track;
		border-radius: $radius-range-track;
	}
	@mixin range-thumb() {
		box-sizing: border-box;
		box-shadow: $shadow-offset-x-range-thumb $shadow-offset-y-range-thumb $size-range-thumb-shadow $color-range-thumb-shadow;
		border: $border-width-range-thumb solid $border-color-range-thumb;
		height: $height-range-thumb;
		width: $width-range-thumb;
		border-radius: $radius-range-thumb;
		background: $background-color-range-thumb;
		cursor: pointer;
	}

	position: relative;
    width: $width;

	//Native
	input[type=range] {
		appearance: none;
		padding: 0;
		width: $width-range-track;
		height: $height-range-bounds;
		cursor: pointer;
		display: block;

		&:focus {
			outline: none;
		}
		&[disabled] {
			opacity: .3;
			cursor: default;
		}
	}

	//Plugin wrapper
	.rangeslider {
		position: relative;
		height: $height-range-bounds;
		cursor: pointer;
		user-select: none;
	}

	//Track
	.rangeslider:before {
		@include range-track();
		content: "";
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	input::-webkit-slider-runnable-track, {
		@include range-track();
		margin: $height-range-bounds / 2 0;
	}
	input::-moz-range-track {
		@include range-track();
		margin: $height-range-bounds / 2 0;
	}
	input::-ms-track {
		@include range-track();
		color: transparent;
		padding: $height-range-bounds / 2 0;
		background: transparent;
		border-color: transparent;
	}

	//Track fill
	input::-ms-fill-lower,
	input::-ms-fill-upper {
		@include range-track();
	}
	input::-ms-fill-lower {
		background: $fill-range-track-active;
	}
	.rangeslider-fill-lower {
		background-color: $fill-range-track-active;
		border-radius: $radius-range-track;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		height: $height-range-track;
		will-change: width;
	}

	//Thumb
	input::-webkit-slider-thumb {
		@include range-thumb();
		appearance: none;
		transform: translateY(-50%);
		margin-top: $height-range-track / 2;
	}
	input::-moz-range-thumb {
		@include range-thumb();
	}
	input::-ms-thumb {
		@include range-thumb();
	}
	.rangeslider-thumb {
		@include range-thumb();
		position: absolute;
		touch-action: pan-x;
		top: 50%;
		transform: translateY(-50%);
		will-change: left;
	}

	.range-output {

		position: absolute;
		left: ($width-range-thumb - $border-width-range-thumb * 2) / 2;
		top: ($width-range-thumb - $border-width-range-thumb * 2) / 2;
		transform-origin: 0 0;
		transition: transform .4s $easeOutBack;
		user-select: none;

		.output {
			display: block;
			position: absolute;
			height: $height-range-output;
			line-height: $height-range-output;
			min-width: $min-width-range-output;
			padding: 0 $padding-x-range-output;
			top: - ($width-range-thumb / 2) - $offset-y-range-output - $size-range-output-arrow;
			transform: translate(-50%, -100%);
			background: $color-range-output;
			color: #fff;
			border-radius: 100px;
			white-space: nowrap;
			font-weight: bold;
			font-size: 1.2em;
			text-align: center;

			&:before {
				content: "";
				position: absolute;
				bottom: - $size-range-output-arrow + 2px; //Subtract 2 to get slight overlap that renders more nicely
				left: 50%;
				border: $size-range-output-arrow solid $color-range-output;
				border-bottom: none;
				border-left-color: transparent;
				border-right-color: transparent;
				transform: translateX(-50%);
			}
		}
	}
}

@media(max-width: 640px) {
    .image-slider-container {
        left: -10px;
    }
}

@media(max-width: $small-break) {
    .image-slider-container {
        left: 0;
    }
}

</style>