<template>
    <div>
        <div class="nav-container">
            <div class="nav-item" v-for="(item,index) in global.navLinks" :key="index" @click="showSubMenu(item,$event)">{{item.name}}</div>
            <div class="nav-list" :style="listLeft" v-if="showSubNavMenu">
                <div 
                class="nav-list-item" 
                v-for="(itemLinks,linkIndex) in selectedItem.links" 
                :key="linkIndex" 
                @click="$router.push(itemLinks.link),showSubNavMenu=false">{{itemLinks.name}}</div>
            </div>
        </div>
    </div>
</template>



<script>
import Globals from '../utilities/globals'

export default {
    data() {
        return {
            global: Globals,
            listLeft: {left: '30px'},
            showSubNavMenu: false,
            selectedItem: {}
        }
    },
    methods: {
        showSubMenu(item,evt) {
            this.selectedItem = item;
            if (item.links) {              
                this.showSubNavMenu = true;
                this.listLeft = {left: (evt.clientX-100) + 'px'}
            } else {
                this.$router.push(item.link)
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.nav-container {
    border-top: 1px solid #cbcdcd;
    border-bottom: 1px solid #cbcdcd;
    text-align: center;
    height: 30px;
    padding-right: 30px
}
.nav-item {
    display: inline;
    padding: 10px;
    line-height: 30px;
    color: #396063;
    cursor: pointer;
}
.nav-divider {
    height: 15px;
    display: inline-block;
    padding-left: 20px;

}
.nav-list {
    position: relative;
    top: 0;
    background-color: white;
    border: 1px solid $primary-light-grey;
    width: 200px;
    text-align: center;
}
.nav-list-item {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid $primary-light-grey;
    cursor: pointer;
}
@media(max-width: $header-break) {
    .nav-container {
        display: none;
    }
}
</style>