<template>
    <div>
        <div class="main-page-header">Injectables</div>
    </div>
    <div class="bio-page-content">
        <div>
            <img class="page-image" src="../assets/images/Botox-Fillers.jpg" alt="">
        </div>
        <div class="page-text">We can help improve your appearance and make you look younger through the use of Injectables and Fillers. Injectables can help erase your fine lines and wrinkles by temporarily reducing the contractions of the underlying muscles of the face. At Aesthetic Treatment Centers we use the most well-known injectables, Botox® and Dysport®, for moderate to severe wrinkles on the forehead, frown lines, and crow’s feet. The dermal fillers Juvederm® and Voluma®, help restore your youthful fullness, shape and stimulate collagen lost during the aging process.</div>
        
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

</style>
