<template>
    <div>
        <div class="main-page-header">EmSculpt Neo</div>
    </div>
    <div class="bio-page-content">
        <div>
            <img class="page-image" src="../assets/images/EmSculpt.jpg" alt="">
        </div>
    </div>

</template>

<script>

export default {
    data () {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

</style>
