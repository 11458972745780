import axios from 'axios'

export default {
    nodeURL: 'https://serve.simplifysoftware.org/',
    // nodeURL: 'http://localhost:443/',
    addMailingList(data) {
        const url = this.nodeURL + 'addmailuser'
        window.console.log('ADD USER', url, data)
        axios({
            method: 'post',
            url: url,
            data: data
        })
        .then(function(response) {
            window.console.log('STORE SERVICES', response.data);
            return response.data.result;
        })
        .catch(function(error) {
            window.console.log(error);
        });
    },
    validateEmail(email) {
        email = email.replace('-','')
        const pattern = /^\w+([.-]?\w+)+@\w+([.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/
        if (email.match(pattern)) {
            return true
        }
        return false
    },
    validatePhone(phone) {
        const pattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/
        if (phone.match(pattern)) {
            return true
        }
        return false
    },
    navLinks: [
        {name: 'Home',link: '/'},
        {name: 'Aesthetic Treatments',
            link: '/aesthetics', 
        },
        {name: 'Weight Management',
            link: '/weight',
        },
        {name: "Women's Health",
            link: '/health',
            },       
        {name: 'About',link: '/about'},
        {name: 'Contact',link: '/contact'},
    ]
}