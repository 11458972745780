<template>
    <div>
        <div class="main-page-header">Weight Management</div>
    </div>
    <div class="bio-page-content">
        <div>
            <div class="side-section">
                <div class="big-image">
                    <img class="page-side-image" src="../assets/images/weightlossVert.jpg" alt="">
                </div>
                <div class="small-image">
                    <img class="page-side-image" src="../assets/images/WeightlossSide.jpg" alt="">
                </div>
            </div>
            <div class="side-info-section">
                <div class="sub-section-container">
                    <div class="sub-section-title">Our Program</div>
                    <div class="sub-section-description">The global obesity epidemic has reached alarming proportions and is one of the most rapidly growing health concerns in the United States. Being overweight or obese now affects over 73% of adults in the US (National Institute of Health, 2023). Our program involves weekly visits and
will include the cost of medications. On your first visit, we will discuss your best options basedon your health status and personal preferences. 
                        
                        <div class="more-section" @click="showPlan=!showPlan">
                            <span v-if="!showPlan">more</span>
                            <span  v-if="showPlan">less</span> 
                            <img class="down-up-icon"  v-if="!showPlan" src="../assets/icons/chevron-down.svg" alt="">
                            <img class="down-up-icon"  v-if="showPlan" src="../assets/icons/chevron-up.svg" alt="">
                        </div>
                        <div class="expand-section" v-if="showPlan">If you have not had lab work in the past three months, we will order lab work and perform a complete body composition analysis. We will discuss an eating and physical activity program that best suits your needs. If appropriate, we will discuss and offer pharmalogical tools to assist in the journey toward a healthier weight. One such tool is semaglutide, a weekly injectable medication initially used to treat type 2 diabetes. Other injectables frequently given at your visits include our Lipo-Mino-mix injection, which is a mixture of vitamins including B1, B2, B6, B12, Inositol, Choline &amp; L-Carnitine to enhance your weight loss effort and boost your energy and metabolism.
                        </div>
                    </div>
                    <div class="sub-section-content"></div>
                </div>
                <div class="sub-section-container">
                    <div class="sub-section-title">Dietary Plans</div>
                    <div class="sub-section-description">We help design a structured and sustainable plan to help you lose weight in a healthy and effective manner. Our program takes into consideration your unique needs, preferences, and goals while promoting long-term changes in eating habits and lifestyle. We take these key components into consideration when designing your dietary weight loss program:
                        
                        <div class="more-section" @click="showPlan=!showPlan">
                            <span v-if="!showPlan">more</span>
                            <span  v-if="showPlan">less</span> 
                            <img class="down-up-icon"  v-if="!showPlan" src="../assets/icons/chevron-down.svg" alt="">
                            <img class="down-up-icon"  v-if="showPlan" src="../assets/icons/chevron-up.svg" alt="">
                        </div>
                        <div class="expand-section" v-if="showPlan">
                        Personalized Nutrition: Our plans are tailored to your age, gender, weight, activity level, and any specific dietary restrictions or preferences. A one-size-fits-all approach is less likely to be successful.<br><br>
                        Calorie Control: Weight loss ultimately comes down to creating a calorie deficit, which means consuming fewer calories than you expend. Our program helps you understand your daily calorie needs and provides strategies to reduce calorie intake in a healthy way.<br><br>
                        Balanced Diet: A well-balanced diet includes a variety of foods from all food groups, including fruits, vegetables, lean proteins, whole grains, and healthy fats. We design our plans to be able to provide essential nutrients while limiting empty calories from sugary and highly processed foods.<br><br>
                        Portion Control: Teaching portion control is crucial to avoid overeating. A good understanding of appropriate portion sizes helps prevent excessive calorie consumption.<br><br>
                        Regular Meals: Regular, balanced meals and snacks throughout the day can help stabilize blood sugar levels, control hunger, and prevent overeating.<br><br>
                        Hydration: Proper hydration is important for overall health and can also help control appetite. Drinking water or other low-calorie beverages is encouraged.<br><br>
                        Nutritional Education: We provide information about the nutritional value of foods, reading food labels, and making informed choices empowering you to make healthier decisions even outside the program.<br><br>
                        Behavioral Changes: Our program addresses the psychological aspects of eating, including emotional eating, stress management, and strategies for overcoming barriers to healthy eating.<br><br>
                        Physical Activity: While diet plays a significant role in weight loss, combining it with regular physical activity enhances results and promotes overall health. We encourage appropriate exercise based on your fitness level.<br><br>
                        Sustainability: We try to design a program that is sustainable over the long term. It tries to teach you how to maintain your weight loss and make healthy choices even after you have achieved your goals.<br><br>
                        Monitoring and Support: We help schedule regular check-ins to help monitor your progress and provide support. Tracking progress and making adjustments as needed is an essential part of the process.<br><br>
                        Realistic Goals: Setting realistic, achievable weight loss goals is crucial to prevent frustration and disappointment.<br><br>
                        Safety: Safety should always be a priority. Extreme calorie restriction or fad diets can be harmful. We try and design a program that results in gradual, steady weight loss within a healthy range.<br><br>
                        Individualized Support: Recognizing that weight loss journeys are unique to each individual we offer individualized guidance and support rather than adopting a one-size-fits-all approach.<br><br>
                        Long-Term Maintenance: Finally, our weight loss program includes strategies for maintaining the achieved weight loss and preventing relapse.</div>
                    </div>
                    <div class="sub-section-content"></div>
                </div>
                <div class="sub-section-container">
                    <div class="sub-section-title">SemaGlutide Treatments</div>
                    <div class="sub-section-description">The search for effective and safe weight loss treatment seems never-ending. The injectable medication SemaGlutide, initially only used as diabetes medication has been found to be effective for weight management. It also highlights the value of treating obesity as a chronic metabolic disease instead of expecting people to rely solely on willpower and lifestyle changes to manage their condition.
                        <div class="more-section" @click="showTreat=!showTreat">
                            <span v-if="!showTreat">more</span>
                            <span  v-if="showTreat">less</span> 
                            <img class="down-up-icon"  v-if="!showTreat" src="../assets/icons/chevron-down.svg" alt="">
                            <img class="down-up-icon"  v-if="showTreat" src="../assets/icons/chevron-up.svg" alt="">
                        </div>
                        <div class="expand-section" v-if="showTreat">Semaglutide belongs to a class of medications known as glucagon-like peptide-1 receptor agonists, or GLP-1 RAs. It mimics the GLP-1 hormone, released in the gut in response to eating. One role of GLP-1 is to prompt the body to produce more insulin, which reduces blood sugar (glucose). For that reason, health care providers have used semaglutide for more than 15 years to treat Type 2 diabetes.<br><br>But GLP-1 in higher amounts also interacts with the parts of the brain that suppress your appetite and signal you to feel full. When used in conjunction with diet and exercise, it can cause significant weight loss — and a reduced risk of cancer, diabetes and heart disease — in people who are obese or overweight.</div>
                    </div>
                    <div class="sub-section-content"></div>
                </div>
                <div class="sub-section-container">
                    <div class="sub-section-title">EmSculpt Neo</div>
                    <div class="sub-section-description">EMSCULPT NEO is a non-invasive treatment that simultaneously combines Radiofrequency heating and HIFEM procedure to reduce fat and build muscle.<br><br>
                    The treatment helps eliminate and removes fat cells from the body while also causing muscle growth through intense muscle contractions.<br><br>
                    Clinical studies have shown an average of 30% reduction in subcutaneous fat and a 25% increase in muscle thickness with EMSCULPT NEO.</div>
                    <div class="sub-section-content"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// import SideItem from '../components/SideItemComponent.vue'

export default {
    components: {
        // SideItem
    },
    data () {
        return {
            showPlan: false,
            showTreat: false,
        }
    },
    methods: {
        // setSelected(item) {
        //     this.showSelected = this.showSelected == item ? '' : item;
        // }
    },
    mounted() {
        window.scrollTo(0,0);
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.page-side-image {
    width: 100%;
}



</style>
