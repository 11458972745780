<template>
    <div class="footer-container">
        <div>
            <div class="primary-button wide-button " @click="showSignup=true">CLICK TO JOIN OUR MAILING LIST</div>
            <div class="join-main-container">
                <div class="join-container" v-if="showSignup">
                    <div class="join-success" v-if="showSuccess">You Have Been Successfully Added to Our Mailing List!</div>
                    <div class="join-title">Join Our Mailing List</div>
                    <div class="join-subtitle">Be the first to hear about our latest specials and updates on all our products and services</div>
                    
                    <input class="join-input" v-model="newuser.email" type="email" placeholder="Email">
                    <div class='missing-selection-message' v-if="showEmailError">*{{'You need to enter a valid email'}}</div>

                    <PhoneInput class="join-phone-input"
                        v-on:number-updated="newuser.phone = $event" 
                        :placeholder="'Phone'">
                    </PhoneInput>
                    <div class='missing-selection-message' v-if="showPhoneError">*{{'You need to enter your phone number'}}</div>

                    <div class="primary-button" @click="checkSubmit">SUBMIT</div>
                    <div class="join-close-button" @click="showSignup=!showSignup">+</div>
                </div>
            </div>
        </div>
        <div class="footer-address">
            <div>599 9th Street North | Suite 300  |  Naples | FL | 34102 </div>
            <div > <a class="footer-address-phone" href="tel:2397994147"> 239-799-4147</a></div>
        </div>
    </div>
</template>

<script>
import Globals from '../utilities/globals'
import PhoneInput from '../components/InputPhoneComponent.vue'
import axios from 'axios'

export default {
    components: {
        PhoneInput
    },
    data() {
        return {
            showSignup: false,
            newuser: {email:'',phone:''},
            showPhoneError: false,
            showEmailError: false,
            showSuccess: false,
        }
    },
    methods: {
        checkSubmit() {
            this.showPhoneError = !Globals.validatePhone(this.newuser.phone);
            this.showEmailError = !Globals.validateEmail(this.newuser.email)
            if (!this.showPhoneError && !this.showEmailError) {
                this.addMailingList(this.newuser)
            }
        },
        addMailingList(data) {
            const url = Globals.nodeURL + 'addmailuser'
            const vm = this
            axios({
                method: 'post',
                url: url,
                data: data
            })
            .then(function(response) {
                if (response.data.result == 'SUCCESS') {
                    vm.showSuccess = true;
                    setTimeout(() => {
                        vm.showSuccess = false;
                        vm.newuser = {}
                        vm.showSignup = false;
                    },3000)
                }
            })
            .catch(function(error) {
                window.console.log(error);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.footer-address {
    text-align: center;
    background-color: $primary-light;
    color: #248587;
    padding: 5px;
}
.footer-address-phone {
    font-size: 20px;
    font-weight: 600;
    height: 25px;
    color: $primary-dark-green;
}
.footer-container {
    width: 100%;
    background-color: #c7dbdd;
    min-height: 50px;
    position: fixed;
    bottom: 0;
    font-weight: 600;
}
.join-title {
    display: inline-block;
}
.join-input {
    display: inline-block;
    height: 20px;
    padding: 5px;
    margin: 5px;
    width: 90%;
}
.join-main-container {
    position: absolute;
    bottom: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}
.join-container {
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-color: $primary-light-green;
    border: 1px solid $primary-dark-green;  
    width: 60%;   
    max-width: 350px;
    position: relative;
}
.join-inline-container {
    display: none;
}
.join-phone-input {
    text-align: left;
}
.join-close-button {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    font-size: 30px;
    right: 15px;
    cursor: pointer;
}
.join-subtitle {
    font-weight: 400;
}
.join-success {
    font-weight: 600;
    color: white;
    font-size: 18px;
    background-color: $primary;
    margin: 20px;
    padding: 5px;
}

@media(max-width: 620px) {
    .join-input {
        display: block;
    }
}
</style>