<template>
    <div>
        <Header/>
        <Navigation/>
        <div class="bio-page-content">            
            <div class="bio-profiles">
                <div class="main-profile-container">
                    <div class="profile-image-container">
                        <img class="main-profile-image" src="../assets/images/KathyStanding.jpg" alt="">
                    </div>
                    <div class="main-profile-content">
                        <div class="main-profile-title">Dr. Kathleen Marc</div>
                        <div class="main-profile-description">"Having worked as a gynecologist and aesthetic provider for many years I can now apply that knowledge and experience to improve the lives of our patients. Our goal is to incorporate the latest techniques and the most natural ingredients and leverage the body's natural regenerative properties to help our patients feel and look younger."</div>
                    </div>
                </div>
                <div class="main-profile-container">
                    <div class="main-profile-content">
                        <div class="main-profile-title">Karen Swain, APRN</div>
                        <div class="main-profile-description">"Having worked with both aesthetics and weight management I feel we can offer a unique combination of services to our patients that take advantage of current technology and the natural holistic practices to provide a unqiue opportunity to help improve our patients lives"</div>  
                    </div>
                    <div class="profile-image-container">
                        <img class="main-profile-image image-right" src="../assets/images/KarenStanding.jpg" alt="">
                    </div>     
                </div>
            </div>
            <div class="ad-container">
                <img class="ad-image" src="../assets/images/CoolPeelLaunchAd2.jpg" alt="">
                <img class="ad-image" src="../assets/images/HelixAd.jpg" alt="">
                <img class="ad-image" src="../assets/images/SecretRFAd.jpg" alt="">
            </div>
            <div class="chart-container">
                <img class="chart-image" src="../assets/images/BioChart.png" alt="">
            </div>
            <div class="mission-container">
                <img class="mission-back" src="../assets/images/MissionStatement.jpg" alt="">
                <!-- <div class="mission-content">
                    <div>Our mission is to leverage the newest scientific advances and the latest natural regenerative techniques to provide our patients with results that help them look and feel younger</div>
                </div> -->
            </div>
            
            <div class="info-block" @click="$router.push('/aesthetics')"> 
                <div class="small-side">
                    <div class="main-topic-title">Aesthetic Treatments</div>
                    <div class="main-topic-description">We offer a comprehensive program to help you look your best.</div> 
                </div> 
                <img class="info-side-image" src="../assets/images/aesthetics.jpg" alt="">
                                        
                <div class="main-side">
                    <div class="large-side">
                        <div class="main-topic-title">Aesthetic Treatments</div>
                        <div class="main-topic-description">We offer a comprehensive program to help you look your best.</div> 
                    </div>                                
                    <div class="topic-item">Injectables</div> 
                    <div class="topic-item">Secret RF</div>
                    <div class="topic-item">MicroNeedling</div>
                    <div class="topic-item">PRP Hair Restoration</div>                                  
                </div>
            </div>    
            
            <div class="info-block" @click="$router.push('/weight')">
                <div class="small-side">
                    <div class="main-topic-title">Weight Management</div>
                    <div class="main-topic-description">We offer a comprehensive program to help you loss weight.</div>  
                </div>                
                <div class="main-side">
                    <div class="large-side">
                        <div class="main-topic-title">Weight Management</div>
                        <div class="main-topic-description">We offer a comprehensive program to help you loss weight.</div> 
                    </div>                                
                    <div class="topic-item">Comprehensive Dietary Plans</div>
                    <div class="topic-item">SemaGlutide Treatments</div>
                    <div class="topic-item">Emsculpt Neo</div>               
                </div>
                <img class="info-side-image" src="../assets/images/weightlossVert.jpg" alt="">
            </div>

            <div class="info-block" @click="$router.push('/health')"> 
                <div class="small-side">
                    <div class="main-topic-title">Women's Health</div>
                    <div class="main-topic-description">We offer ways to stay vital..</div>  
                </div> 
                <img class="info-side-image" src="../assets/images/Feminine_Rejuvenation.jpg" alt="">              
                <div class="main-side">
                    <div class="large-side">
                        <div  class="main-topic-title">Women's Health</div>
                        <div class="main-topic-description">We offer ways to stay vital.</div> 
                    </div>                                
                    <div class="topic-item">ThermiVa</div>
                    <div class="topic-item">PRP Vaginal Rejuvenation</div>           
                </div>  
                <div @click="copyDoiToClipboard('something to copy')">
                </div>             
            </div> 
            <div article-section>
                <div class="section-title">Articles</div>
                <div class="article-container">
                    <img @click="showArticle('semaglutide')" 
                        class="article-image" 
                        src="../assets/images/SemaGlutideArticle.jpg" alt="">
                </div>
                <div class="article-container">
                    <img @click="showArticle('exosomes')" 
                        class="article-image" 
                        src="../assets/images/ExosomeArticle.jpg" alt="">
                </div>
                <div class="article-container">
                    <img @click="showArticle('sexualhealth')" 
                        class="article-image" 
                        src="../assets/images/SexualHealthArticle.jpg" alt="">
                </div>
                <div class="article-container">
                    <img @click="showArticle('vampire')" 
                        class="article-image" 
                        src="../assets/images/HW_Vampire.png" alt="">
                </div>
                <div class="article-container">
                    <img @click="showArticle('regen')" 
                        class="article-image" 
                        src="../assets/images/HW_Regenerative.png" alt="">
                </div>
            </div>
            <div class="video-section">
                <div class="section-title">Informative Videos</div>
                <div class="video-container">
                   <iframe width="360" height="202.5" src="https://www.youtube.com/embed/vpxlZx_ATtA?si=5-Lb0GXm8ojz0xT6" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="video-container">
                    <iframe width="360" height="202.5" src="https://www.youtube.com/embed/nFJ17UixZ8s?si=On7rUukYDcs36BFj" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="video-container">
                    <iframe width="360" height="202.5" src="https://www.youtube.com/embed/QK5ozplqh_0?si=4UCBG9eXYX92MXqv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="video-container">
                    <iframe width="360" height="202.5" src="https://www.youtube.com/embed/GeRCM8gEzGA?si=yFKfoui1_4STYIh2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                <div class="video-container">
                    <iframe width="360" height="202.5" src="https://www.youtube.com/embed/6II4Y5FQ9_E?si=JYsAIZGqbgtcJ-Ir" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                
            </div>
            <div class="info-affiliated">We are affiliated with<br><a href="https://naplesdermdoc.com/" target="_blank">High Tide Dematology</a></div>     
        </div>

    </div>
</template>

<script>


export default {
   
    methods: { 
        copyDoiToClipboard (doi) {
            console.log("COPY", doi)
            navigator.clipboard.writeText(doi)
        },
        showArticle(articleName) {
            let pdfUrl = 'https://video.bioaestheticsnaples.com/';
            switch(articleName) {
                case 'semaglutide':
                    pdfUrl += 'semaglutide-article.pdf';
                    break;
                case 'sexualhealth':
                    pdfUrl += 'BioAesthetics-SexualHealth-Article.pdf';
                    break;
                case 'exosomes':
                    pdfUrl += 'HW_Exosomes.pdf';
                    break;
                case 'vampire':
                    pdfUrl += 'HW_Vampire.pdf';
                    break;
                case 'regen':
                    pdfUrl += 'HW_RegenerativeMedicine.pdf';
                    break;
            }

            window.open(pdfUrl, '_self')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.info-affiliated {
    color: $primary-dark-green;
    font-size: 18px;
    padding: 10px;
    padding-bottom: 20px;

    a{
        color: $primary-dark-green;
    }
}
.chart-container {
    border-bottom: 1px solid $primary-light-grey;
}
.chart-image {
    width: 100%;
}
.ad-image {
    width: 80%;
    margin: 10px;
}
.mission-back {
    width: 100%;
    position: relative;
}
.mission-container {
    position: relative;
    // border-bottom: 1px solid $primary-light-grey;
}
.mission-content {
    font-size: 24px;
    font-weight: 700;
    font-style: italic;
    display: block;
    padding: 30px;
    padding-top: 0;
    color: #3d80a6;
    line-height: 26px;
}
.info-side-image {
    width: 35%;
    display: inline-block;
    vertical-align: bottom;
}
.main-side {
    display: inline-block;
    padding: 20px;
    vertical-align: top;
    text-align: left;
    width: 58%;
    padding-top: 0;
}
.info-block {
    text-align: left;
    border-bottom: 1px solid $primary-light-grey;
}
.main-topic-title {
    font-size: 24px;
    font-weight: 600;
    
}
.main-topic-description {
    font-size: 22px;
    line-height: 22px;
    margin: 20px;

}
.topic-item {
    padding: 10px 50px;
    color: $primary;
    font-size: 24px;
    line-height: 26px;
}
.main-profile-image {
   width: 100%;
}
.profile-image-container {
    display: inline-block;
    vertical-align: bottom;
    width: 35%;
}
.main-profile-container {
    text-align: left;
    border-bottom: 1px solid $primary-light-grey;
    display: inline-block;
    width: 50%;
}
.main-profile-content {
    display: inline-block;
    vertical-align: top;
    padding: 25px 10px 10px 10px;
    width: 58%;
    
}
.main-profile-description {  
    vertical-align: top;
    font-style: italic;
    font-size: 1.2em;
    @media (min-width: 1400px) {
        font-size: 1.7em;
    }
    @media (min-width: 2200px) {
        font-size: 3em;
    }
}
.main-profile-title {
    font-weight: 600;
    color: $primary-dark-green;
    font-size: 20px;
    @media (min-width: 1400px) {
        font-size: 1.7em;
    }
    @media (min-width: 2200px) {
        font-size: 3em;
    }
}
.bio-profiles {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.image-right {
    float: right;
}
.small-side {
    display: none;
}
.large-side {
    padding-top: 20px;
}
.article-image {
    height: 250px;
    border: 1px solid grey;
}
.article-container {
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    
}
.video-container {
    border: 1px solid grey;
    padding: 10px;
    margin: 10px;
    display: inline-block;
    background-color: white;
}
.video-section {
    background-color: #f4f4f4;
}
.section-title {
    font-size: 24px;
    font-weight: 600;
    padding-top: 10px;
}
@media(max-width: 715px) {
    .main-profile-container {
        width: 100%;
        // display: block;
        display: flex;
        justify-content: space-between;
    }
    .bio-profiles {
        display:block;
    }
    .main-side {
        width: 54%;
    }
    
}
@media(max-width: 540px) {
    .large-side {
        display: none;
    }
    .small-side {
        display: block;
        padding-left: 20px;
        padding-top: 10px;
    }
}
</style>