<template>
    <div class="side-info-item">
        <div  class="side-panel" @click="setSelected(name)">
            <div class="side-info-item-title">{{name}}</div>
            <div class="side-info-nav-button" v-if="showSelected !==name">
                <img class="nav-icon" src="../assets/icons/chevron-down.svg" alt="">
            </div>
            <div class="side-info-nav-button"  v-if="showSelected==name">
                <img class="nav-icon" src="../assets/icons/chevron-up.svg" alt="">
            </div>
        </div>
        <div class="side-info-detail" v-if="showSelected==name">
            <div v-html="content"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['name','content'],
    data() {
        return {
            showSelected: ''
        }
    },
    methods: {
        setSelected(item) {
            this.showSelected = this.showSelected == item ? '' : item;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.page-side-image {
    width: 100%;
}
.side-section {
    display: inline-block;
    width: 40%;
    vertical-align: top;
}
.side-info-section {
    display: inline-block;
    width: 65%;
    text-align: left;
}
.side-info-item {
    min-height: 40px;
    padding: 10px;
    border-bottom: 1px solid $primary-light-grey;
    line-height: 40px;
    position: relative;
}
.side-info-detail {
    line-height: 18px;
    padding-left: 20px;
}
.side-info-item-title {
    color: $primary-dark-green;
    font-weight: 500; 
}
.side-info-nav-button {
    background-color: $primary-light;
    height: 25px;
    width: 25px;
    border-radius: 12px;
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: pointer;
}
.side-panel {
    cursor: pointer;
}
.nav-icon {
    width: 60%;
    padding-left: 5px;
    padding-bottom: 4px;
}
@media(max-width: $semi-break) {
    .side-info-item-title {
        max-width: 70%;
        line-height: 18px;
        padding-top: 12px;
    }
    .side-info-detail {
        line-height: 18px;
        padding: 5px;
    }
}
</style>