<template>
    <div>
        <div class="main-page-header">Aesthetic Treatments</div>
    </div>
    <div class="bio-page-content">
        <div>
            <div class="side-section">
                <img class="page-side-image" src="../assets/images/aesthetics.jpg" alt="">
            </div>
            <div class="side-info-section">
                <div class="sub-section-container">
                    <div class="sub-section-title">Secret RF</div>
                    <div class="sub-section-description">
                        <div>SKIN REVITALIZATION AND RESURFACING</div>
                        <div>Secret RF is an innovative fractional radio frequency (RF) microneedling system for tissue coagulation and hemostasis designed to stimulate and remodel collagen from the inside out. Secret RF delivers precise, controlled energy at various depths to target patients’ most common skin concerns on the face and body with little to no downtime.</div>
                        <ImageSlider></ImageSlider>
                        <EyeSlider></EyeSlider>
                    </div>
                    <div class="sub-section-content"></div>
                </div>
                <div class="sub-section-container">
                    <div class="sub-section-title">MicroNeedling</div>
                    <div class="sub-section-description">Microneedling is a procedure that uses small needles to prick the skin creating tinyl wounds. The purpose of treatment is to generate new collagen and skin tissue for smoother, firmer, more toned skin. Microneedling is mostly used on the face to minimize scars, wrinkles, and large pores while tightening or rejuvenating loose or aging skin. With microneedling collagen production is accelerated, along with cellular turnover. While some results may be almost immediate, your best results usually won't be visible until four to six weeks after the procedure.</div>
                    <div class="sub-section-content">
                        <SideItem 
                            :name="'MicroNeeding with Growth Factors'"
                            :content="`Microneedling is a procedure that uses small needles to prick the skin creating tinyl wounds. The purpose of treatment is to generate new collagen and skin tissue for smoother, firmer, more toned skin. Microneedling is mostly used on the face to minimize scars, wrinkles, and large pores while tightening or rejuvenating loose or aging skin. With microneedling collagen production is accelerated, along with cellular turnover. While some results may be almost immediate, your best results usually won't be visible until four to six weeks after the procedure.<br><br>PRP stands for platelet-rich plasma, and its addition is taking microneedling treatments to the next level. Your blood contains red cells, white cells, and platelets. The platelets contain hundreds of proteins called growth factors which are very important for healing injury, especially to the surface layers of the skin.<br><br>For a PRP treatment, your blood is spun down in a centrifuge to separate the platelet rich plasma from the blood cells. The plasma is then applied to your skin during a microneedling treatment. Basically, your blood, hyper-concentrated with these growth factors, is injected back into the body.`">
                        </SideItem>
                        <SideItem 
                            :name="'MicroNeeding with Exosomes'"
                            :content="`Microneedling is a procedure that uses small needles to prick the skin creating tinyl wounds. The purpose of treatment is to generate new collagen and skin tissue for smoother, firmer, more toned skin. Microneedling is mostly used on the face to minimize scars, wrinkles, and large pores while tightening or rejuvenating loose or aging skin. With microneedling collagen production is accelerated, along with cellular turnover. While some results may be almost immediate, your best results usually won't be visible until four to six weeks after the procedure.<br><br>Exosomes are micro-vesicles that are responsible for communicating from cell to cell. You can think of them as the cell phone of the cell. They essentially tell tissue and other cells what to do; for example, if they’re introduced to a wound, they can tell the skin to produce more collagen to speed up cellular turnover. Because exosomes are the center of cellular communication, we’ve learned to program them to perform specific actions that produce a client’s ideal results.<br><br>Microneedling with exosomes treatment provides clinically-proven skin rejuvenation and regeneration of skin that has the ability to harnesses the power of over 2 billion stem cells. Exosomes are the cell communicators, they tell cells what to do and transfer numerous growth factors and cytokines and genetic materials that have shown signs of tissue repair and improving skin cells. Using exosomes in microneedling helps the skin become brighter and more hydrated. Redness and skin irritation will decline, skin lesions, scars, textures and pore size will be improved.`">
                        </SideItem>
                    </div>
                </div>
                <div class="sub-section-container">
                    <div class="sub-section-title">EmSculpt Neo®</div>
                    <div class="sub-section-description">EMSCULPT NEO is a non-invasive treatment that simultaneously combines Radiofrequency heating and HIFEM procedure to reduce fat and build muscle.<br><br>
                    The treatment helps eliminate and removes fat cells from the body while also causing muscle growth through intense muscle contractions.<br><br>
                    Clinical studies have shown an average of 30% reduction in subcutaneous fat and a 25% increase in muscle thickness with EMSCULPT NEO.</div>
                    <div class="sub-section-content"></div>
                </div>   
                <div class="sub-section-container">
                    <div class="sub-section-title">Smooth Threading</div>
                    <div class="sub-section-description">Smooth threads are used in cosmetic treatments because of their proven ability to induce collegan production in the skin. Smooth threads are clinically proven to make skin more firm, fight wrinkles and improve the skins overall appearance. 
                        <div class="more-section" @click="showSmooth=!showSmooth">
                            <span v-if="!showSmooth">more</span>
                            <span  v-if="showSmooth">less</span> 
                            <img class="down-up-icon"  v-if="!showSmooth" src="../assets/icons/chevron-down.svg" alt="">
                            <img class="down-up-icon"  v-if="showSmooth" src="../assets/icons/chevron-up.svg" alt="">
                        </div>
                        
                        <div class="expand-section" v-if="showSmooth">
                            Collagen helps support "growth factors" that greatly influences the condition of our skin. In addition to being used for wound healing, collagen helps to keep our skin strong, voluminous and supple. As we get older, our bodies gradually produce less and less collagen, which leads to an 80% reduction in skin thickness by about age 70. This loss of volume and strength is a large factor in the creation of excess skin and wrinkles. As the skin grows weaker, it's no longer able to support the tissues beneath it adequately, meaning that gravity pulls it downwards and stretches it. Infusing the skin of the face with fresh collagen when the signs of skin laxity are still mild can help to both reduce looseness (by thickening and hydrating the skin) and prevent it from getting worse (by strengthening the skin).<br><br>In other words, this process provides ongoing and progressive rejuvenation for the facial tissues. Patients who have smooth threading for the purpose of stimulating collagen will notice a gradual improvement in their skin's tone and firmness. While their threads are in place, the body's healing response will be constantly activated because the body will want to "heal" the sutured areas and expel the sutures. The body is biologically programmed to react this way when it senses any foreign object present within the dermis. Fortunately, because the threads placed under the skin during a smooth threading are so small, the patient will not feel any of this happening. Most people cannot feel their sutures at all once the skin has healed around them.
                        </div>
                    </div>
                    <div class="sub-section-content"></div>
                </div>              
                <div class="sub-section-container">
                    <div class="sub-section-title">Injectables</div>
                    <div class="sub-section-description">Injectables are used to relax facial wrinkles and folds (such as “smile lines”), contour the body (such as reducing the appearance of a “double chin”) and improve the signs of facial fat loss by creating structure, framework and volume to the face and lips. There are different types of injectable procedures that improve various areas of concern.</div>
                    <div class="sub-section-content">
                        <SideItem 
                            :name="'Botox®'"
                            :content="`Here is some additional content that describes what is being done and gives some additional clarification as to what is being offered`">
                        </SideItem>
                        <SideItem 
                            :name="'Dysport®'"
                            :content="`Don't let your frown lines between your eyebrows define you. For adults who want a natural look, Dysport is a natural-looking, fast-acting, long-lasting prescription injection proven to help smooth moderate to severe frown lines between the eyebrows--without changing the look or movement of the rest of your face.`">
                        </SideItem>
                        <SideItem 
                            :name="'Juvederml Ultra®'"
                            :content="`Juvederm comprises hyaluronic acid (HA). This is a natural complex sugar that enhances the volume and elasticity of the skin. It is naturally found in all living organisms. HA facilitates the delivery and retention of nutrients and moisture to the skin, and levels this compound in the skin naturally decrease with the process of aging. <br><br>The HA in Juvederm is developed using a cutting-edge production process. It is specially formulated as a smooth, malleable gel to make sure that the most natural-looking outcomes possible.<br><br>The procedure can be used to contour the face and soften the appearance of scars, such as acne scarring.<br><br>In addition, Juvederm can be used to improve the shape and size of the lips. All of these regions will show significant improvement after the Juvederm filler treatment.`">
                        </SideItem>
                        <SideItem 
                            :name="'Juvederm Ultra Plus®'"
                            :content="`Juvederm Ultra Plus is part of a family of specialized hyaluronic acid dermal fillers that range in viscosity (thickness) and other factors to achieve optimal results in different areas. We inject this gel-like substance beneath the skin to help smooth fine lines and wrinkles, soften creases, enhance contours, and restore volume instantly.`">
                        </SideItem>
                        <SideItem 
                            :name="'Juvederm Voluma® XC'"
                            :content="`JUVÉDERM® VOLUMA® XC injectable gel is for deep injection in the cheek area to correct age-related volume loss and for augmentation of the chin region to improve the chin profile in adults over 21.`">
                        </SideItem>
                        <SideItem 
                            :name="'Restylane Refyne®'"
                            :content="`Restylane Refyne nasolabial filler banishes unsightly folds, and Restylane Refyne lips adds that extra plump to your pout! Restylane Refyne under eyes softens those dark tear troughs, and you will love what Refyne filler for lips does for your kisser! Refyne is a hyaluronic acid dermal filler produced by Galderma to help smooth out moderate to severe facial lines (nasolabial folds and marionette lines). Refyne is used to fill more fine wrinkles and lines, Restylane Refyne for lips helps restore volume and reduce wrinkles in that area, also.`">
                        </SideItem>
                        <SideItem 
                            :name="'Restylane Defyne®'"
                            :content="`Restylane Defyne is a hyaluronic acid dermal filler that helps remove laugh lines (nasolabial folds and marionette lines). Restylane Defyne is injected more deeply under the skin to create more volume and lift, especially in the lower face area. Restylane Defyne injections fill the deepened and marionette lines around the mouth that appear as we age.`">
                        </SideItem> 
                        <SideItem 
                            :name="'Restylane Contour®'"
                            :content="`Restylane Contour is the only cheek filler designed with XpresHAn Technology™ to restore cheek definition wiith natural-looking results and flexible movement with each vibrant expression.`">
                        </SideItem>                      
                        <SideItem 
                            :name="'Restylane Kysse®'"
                            :content="`Restylane Kysse is a dermal filler injected into the lips and perioral lip lines around the upper lip. Restylane Kysse increases lip fullness and improves overall aesthetic appearance.`">
                        </SideItem>
                        <SideItem 
                            :name="'Versa®'"
                            :content="`This FDA-approved injectable hyaluronic acid filler treats moderate to severe facial wrinkles and folds or augments specific facial features. Versa has a high percentage of biocompatible spherical particles that provide smoothness and volume. The particles reduce swelling when compared to similar dermal fillers.`">
                        </SideItem>
                        <SideItem 
                            :name="'Versa Lips®'"
                            :content="`Revanesse® Lips™+ is an FDA-approved hyaluronic acid (HA) dermal filler that can be used to restore lost volume and create a fuller-looking lip in patients 22 years of age and older.`">
                        </SideItem>
                        <SideItem 
                            :name="'Sculptra®'"
                            :content="`Sculptra is a biostimulating injectable dermal filler that helps fight aging skin. The formula is a mixture of powder, water/saline solution, and poly-L-lactic acid (PLLA) produced by Galderma. The poly-L-lactic acid works subtly and gradually over time for a more youthful appearance, stimulating natural collagen production in the skin to help restore deteriorated bone structure and increase volume.`">
                        </SideItem>
                        <SideItem 
                            :name="'Radiesse®'"
                            :content="`RADIESSE and RADIESSE (+) are demal fillers that are used for smoothing moderate to severe facial wrinkles and folds, such as nasolabial folds (the creases that extend from the corner of your nose to the corner of your mouth). RADIESSE is also used for correcting volume loss in the back of the hands. Hyperdilute Radiesse may be used in other parts of the body, such as decollete to stimulate collegan growth and reduce wrinkling`">
                        </SideItem>
                    </div>
                </div>
                <div class="sub-section-container">
                    <div class="sub-section-title">PRP Hair Restoration</div>
                    <div class="sub-section-description">PRP, or Platelet-Rich Plasma therapy involves extracting a small amount of the patient's blood, processing it to separate the platelet-rich plasma from other components, and then injecting this concentrated plasma into the scalp. Platelets contain growth factors and other bioactive proteins that can stimulate hair follicles and promote hair growth.<br><br>
                    PRP therapy works for hair restoration through several mechanisms:<ul>
                        <li>Stimulation of Hair Follicles: Growth factors in PRP can stimulate dormant hair follicles, making them more active and encouraging hair growth.</li>
                        <li>Improved Blood Circulation: PRP can enhance blood circulation to the scalp, providing essential nutrients and oxygen to the hair follicles.</li>
                        <li>Reduction of Inflammation: PRP may help reduce inflammation in the scalp, which can be a contributing factor to hair loss.</li>
                    </ul>
                    The number of PRP sessions required for hair restoration varies from person to person and we typically recommend a series of treatments spaced a few weeks apart. Maintenance sessions may also be needed to sustain the results.
                    </div>
                    <div class="sub-section-content"></div>
                </div>          
            </div>
        </div>
    </div>

</template>

<script>
import SideItem from '../components/SideItemComponent.vue'
import ImageSlider from '../components/sliderComponent.vue'
import EyeSlider from '../components/BAEye.vue'
export default {
    components: {
        SideItem,
        ImageSlider,
        EyeSlider
    },
    data () {
        return {
            showSelected:'',
            showSmooth: false,
        }
    },
    methods: {

    },
    mounted() {
        window.scrollTo(0,0);
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.page-side-image {
    width: 100%;
}
// .side-section {
//     display: inline-block;
//     width: 40%;
//     vertical-align: top;
// }
// .side-info-section {
//     display: inline-block;
//     width: 60%;
//     text-align: left;
// }
.side-info-item {
    min-height: 40px;
    padding: 10px;
    border-bottom: 1px solid $primary-light-grey;
    line-height: 40px;
    position: relative;
}
.side-info-detail {
    line-height: 18px;
    padding-left: 20px;
}
.side-info-item-title {
    max-width: 50%;
    color: $primary-dark-green;
    font-weight: 500;
}
.side-info-nav-button {
    background-color: $primary-light;
    height: 25px;
    width: 25px;
    border-radius: 12px;
    display: inline-block;
    position: absolute;
    right: 25px;
    top: 20px;
    cursor: pointer;
}
.nav-icon {
    width: 60%;
    padding-left: 5px;
    padding-bottom: 4px;
}


</style>
