<template>
    <div>

        <div class="request-button"  @click="openAppointment">Book an Appointment</div>
        <div class="header-image-container">
            <img class="header-image" src="../assets/images/BioaestheticsLogo.png" alt="" @click="$router.push('/')">
            <div class="social-media-icons">
                <!-- <img class="media-icon" src="../assets/icons/twitter.svg" alt=""> -->
                <img class="media-icon" @click="goSocial('facebook')" src="../assets/icons/facebook.svg" alt="">
                <img class="media-icon" @click="goSocial('instagram')" src="../assets/icons/instagram.svg" alt="">
                <img class="media-icon" @click="goSocial('tiktoc')" src="../assets/icons/tiktoc.svg" alt="">
            </div>
            <img class="bio-icon" src="../assets/icons/bars.svg" @click="showNav=!showNav,subSelected=''">
            <div class="request-bar">
                <div class="inline-button" @click="openAppointment">Book an Appointment</div>
            </div>
            <div class="nav-list" v-if="showNav">
                <div class="nav-list-item" v-for="(item,index) in global.navLinks" :key="index"  @click="showSubMenu(item)">
                    <div>{{item.name}}</div>
                    <div v-if="subSelected===item.name">
                        <div 
                            class="nav-list-sub-item" 
                            v-for="(subItem,subIndex) in item.links" 
                            :key="subIndex"
                            @click="subSelected='',$router.push(subItem.link),showNav=false">
                            {{ subItem.name }}
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Globals from '../utilities/globals'

export default {
    data () {
        return {
            showNav: false,
            global: Globals,
            subSelected: ''
        }
    },
    methods: {
        showSubMenu(item){
            console.log('ITEM', item)
            if (item.links) {
                this.subSelected = item.name
            } else {
                this.$router.push(item.link)
                this.showNav = false;
            }
        },
        openAppointment() {
            console.log('OPEN APT')
            window.open('https://bioaestheticsnaples.janeapp.com', '_self');
            
        },
        goSocial(site) {
            if (site === 'facebook') {
                window.open('https://www.facebook.com/profile.php?id=61551782987215', '_self');
            }
            if (site === 'instagram') {
                window.open(' https://www.instagram.com/bioaestheticsnaples/', '_self');
            }
            // if (site === 'tictoc') {
            //     window.open('bioaestheticsnaples@gmail.com', '_blank');
            // }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.header-image {
    height: 150px;
}
.bio-icon {
    top: 70px;
    height: 32px;
    position: relative;
    right: 0;
    position: absolute;
    right: 40px;
    display: none;
    cursor: pointer;

    @media(max-width: 450px) {
        top: 50px;
    }
}
.nav-list-item {
    border-bottom: 1px solid;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}
.nav-list-sub-item {
    border-top: 1px solid $primary-light-grey;
    background-color: $primary-light-green;
}
.nav-list {
    background-color: #f5ffff;
    border-top: 1px solid;
    position: absolute;
    width: 100%;
    z-index: 5;
}
.header-image-container{
    width: 100%;
    text-align: center;

    @media(max-width: 450px) {
        text-align: left;
    }
}
.social-media-icons {
    top: 10px;
    position: absolute;
    right: 10px;

    
}
.media-icon {
    height: 25px;
    padding: 0 10px;
}

.request-bar {
    position: relative;
    height: 41px;
    background-color: $primary;
    width: 100%;
    display: none;
    
    @media(max-width: $header-break) {
        display: block;
        text-align: center;
    }
}
.request-button {
    background-color: $primary-light;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin: 5px;
    position: absolute;
    box-shadow: 2px 2px 5px #808181;
    top: 23px;
    left: 20px;
    cursor:pointer;

    @media(max-width: 860px) {
        top: 3px;
        left: 38%;
    }
    @media( max-width: $header-break) {
        display: none;
    }
}
.inline-button {
    background-color: $primary-offwhite;
    padding: 5px 15px;
    display: inline-block;
    font-weight: 600;
    margin: 5px;
    box-shadow: 2px 2px 5px #808181;
    cursor:pointer;
}

@media(max-width: $header-break) {
   .bio-icon { 
        display: block;
    }
    .header-image {
        width: 60%;
        height: auto;
    }
}
</style>