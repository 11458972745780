<template>
  <div id="app" class="main-app-container">
    <Header/>
    <Navigation/>
    <router-view/>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/HeaderComponent.vue'
import Navigation from './components/NavigationComponent.vue'
import Footer from './components/FooterComponent.vue'

export default {
  name: 'App',
  components: {
   Header,
   Navigation,
   Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
}
body {
  margin: 0;
}
</style>
