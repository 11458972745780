import { createWebHistory, createRouter } from "vue-router";
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import ContactPage from './pages/ContactPage'
import SecretPage from './pages/SecretRFPage'
import InjectablesPage from './pages/InjectablesPage'
import MicroNeedlingPage from './pages/MicroNeedlingPage'
import DietPlansPage from './pages/DietPlansPage'
import EmSculptPage from './pages/EmSculptPage'
import SemiGlutidePage from './pages/SemiGlutidePage'
import ThermivaPage from './pages/ThermivaPage'
import WomenPRPPage from './pages/WomenPRPPage'
import WeightPage from './pages/WeightPage'
import WomenHealthPage from './pages/WomenHealthPage'
import AestheticsPage from './pages/AestheticsPage'


const routes = [
    { path: '/', component: HomePage },
    {
        name: 'About',
        path: '/about',
        component: AboutPage,
    },
    {
        name: 'Contact',
        path: '/contact',
        component: ContactPage,
    },
    {
        name: 'Secret RF',
        path: '/secretRF',
        component: SecretPage,
    },
    {
        name: 'Injectables',
        path: '/injectables',
        component: InjectablesPage,
    },
    {
        name: 'MicroNeedling',
        path: '/microneedling',
        component: MicroNeedlingPage,
    },
    {
        name: 'Diet Plans',
        path: '/dietplans',
        component: DietPlansPage,
    },
    {
        name: 'EmSculpt',
        path: '/emsculpt',
        component: EmSculptPage,
    },
    {
        name: 'SemiGlutide',
        path: '/semiglutide',
        component: SemiGlutidePage,
    },
    {
        name: 'ThermiVa',
        path: '/thermiva',
        component: ThermivaPage,
    },
    {
        name: 'Women PRP',
        path: '/womenprp',
        component: WomenPRPPage,
    },
    {
        name: 'Weight Management',
        path: '/weight',
        component: WeightPage,
    },
    {
        name: 'Women Health',
        path: '/health',
        component: WomenHealthPage,
    },
    {
        name: 'Aesthetic Treatments',
        path: '/aesthetics',
        component: AestheticsPage,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
  });
export default router;