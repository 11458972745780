<template>
    <div>
        <div class="main-page-header">About</div>
    </div>
    <div class="bio-page-content">
        <div class="about-section-title">Staff</div>
        <div>
            <div class="staff-image-container">
                <img class="staff-image" src="../assets/images/KathyMed.jpg" alt="">
            </div>
            <div  class="staff-info-container">
                <div class="staff-title" ><span class="staff-title-name">Kathleen M. Marc, MD</span>    Medical Director  </div>
                <div  class="page-text">Dr. Marc is a highly accomplished medical professional with over 25 years of dedicated practice in the field of gynecology and minimally invasive surgery. Based in Boston, she served as the Associate Chair of Gynecology at Mass General Brigham/Newton Wellesley Hospital, showcasing her expertise and commitment to women's health.<br><br>She earned her Bachelor of Science in Psychobiology from Tufts University, further pursuing her passion by obtaining her MD from Tufts Medical School. Dr. Marc is a Board Certified member of the American College of OB/GYN, a testament to her exceptional qualifications and proficiency in her specialty.<br><br>Dr. Marc's relentless pursuit of knowledge led her to attain advanced certifications in Aesthetic and Regenerative Medicine, demonstrating her dedication to staying at the forefront of medical advancements. She skillfully combines her extensive medical experience and surgical proficiency with the latest aesthetic regenerative techniques to empower her patients, assisting them in looking and feeling rejuvenated.<br><br>In addition to her clinical practice, Dr. Marc holds esteemed academic positions, serving as an Associate Professor at Tufts University School of Medicine and a Clinical Instructor at Harvard Medical School. Her influence and expertise extend beyond her practice, as she also held the distinguished position of Past President of the New England OB/GYN Society, showcasing her leadership and impact within the medical community.<br><br>Through her involvement with Bioaesthetics, Dr. Marc continues to embody her dedication to advancing patient care and well-being, ensuring that each individual she encounters receives the highest standard of medical care and personalized attention, contributing to a healthier, more vibrant life.<br><br>
                </div>
        </div>
        <div>
            <div class="staff-image-container">
                <img class="staff-image" src="../assets/images/KarenSwain.jpg" alt="">
            </div>
            <div  class="staff-info-container">
                <div class="staff-title" ><span class="staff-title-name">Karen Swain</span>, MSN, APRN, FNP-C</div>           
                <div  class="page-text">
                    Karen Swain is a distinguished Board-Certified Nurse Practitioner with a wealth of experience and expertise across various facets of the medical field, particularly in critical care. Her illustrious career in the aesthetic and regenerative medicine industry spans over two decades, primarily within the Dallas/Fort Worth area.<br><br>Karen's foundation in nursing was established at the University of Texas at Arlington, where she graduated summa cum laude with a Bachelor of Science in Nursing. Building on this solid education, she pursued higher studies at Houston Baptist University, attaining a Master of Science in Nursing.<br><br>With a keen eye for advancements in medical technology, Karen has garnered extensive proficiency in multiple laser platforms, neurotoxins, and soft tissue fillers, making her a prominent figure in the field. Her dedication to healthcare extends beyond her professional commitments, as she actively supports various local charities throughout the year, embodying a true spirit of community engagement.<br><br>Karen's leadership within medical organizations speaks to her commitment to advancing healthcare initiatives. She has served as the President of the Collier County Medical Society Alliance and holds the distinction of being the immediate past President of the Florida Medical Association Alliance, demonstrating her advocacy for the medical community at a regional level.<br><br>Residing in Naples alongside her husband, Gary, Karen Swain continues to make significant contributions to the healthcare industry and enrich the lives of the community through her expertise, compassion, and philanthropic endeavors.<br><br>
                </div>
            </div>
            <div class="staff-image-container">
                <img class="staff-image" src="../assets/images/Loreta.jpg" alt="">
            </div>
            <div  class="staff-info-container">
                <div class="staff-title" ><span class="staff-title-name">Loretta Savicke</span></div>           
                <div  class="page-text">
                    With an unyielding commitment to providing high-quality care, Loretta's journey in the healthcare industry began 25 years ago, marking the inception of an extraordinary career devoted to patient well-being and personal transformation. Over the years, she has honed her expertise and skills, becoming a trusted name in the world of aesthetic nursing.<br><br>Loretta's proficiency encompasses a diverse range of treatments that focus on enhancing one's natural beauty. Her skill set includes microneedling, a technique recognized for its ability to rejuvenate and revitalize the skin. Additionally, she excels in dermaplaning, a method known for delivering a smooth, radiant complexion by removing dead skin cells and peach fuzz.<br><br>At the heart of Loretta's practice is her unwavering dedication to ensuring that every individual she serves receives a personalized experience. Her expertise also extends to the popular HydraFacial treatment, a soothing and rejuvenating procedure that cleanses, exfoliates, and hydrates the skin, leaving it with a radiant and youthful glow.<br><br>Moreover, Loretta is known for her proficiency in waxing, providing clients with a comfortable and efficient hair removal experience. She combines her extensive experience with a gentle touch to ensure the utmost comfort for her patients.<br><br>Loretta Savicke's passion for helping people look and feel their best is evident in her remarkable career. With 25 years of nursing experience and 15 years spent enriching the lives of individuals in the United States, she continues to be a respected figure in the world of aesthetic nursing. Her dedication to delivering top-tier care and her comprehensive skill set in facial treatments, microneedling, dermaplaning, HydraFacial, and waxing make her a go-to expert for those seeking to enhance their natural beauty.<br><br>
                </div>
            </div>
        </div>
        

    </div>
</div>

</template>

<script>

export default {
    data () {
        return {

        }
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.staff-image-container {
    width: 20%;
    display: inline-block;
    vertical-align: top;
}
.staff-image {
    width: 80%;
}
.staff-info-container {
    width: 75%;
    display: inline-block;
}
.staff-title {
    font-size: 20px;
    font-weight: 600;
    text-align: left;
}
.about-section-title {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    padding: 25px;
}
.staff-title-name {
    font-size: 30px;
    color:#0c686d;
}
</style>
