<template>
    <div>
        <div class="main-page-header">Women's Health</div>
    </div>
    <div class="bio-page-content">
        <div>
            <div class="side-section">
                <div class="big-image">
                    <img class="page-side-image" src="../assets/images/WomenHealth.jpg" alt="">
                </div>
                <div class="small-image">
                    <img class="page-side-image" src="../assets/images/Feminine_Wide.jpg" alt="">
                </div>
            </div>
            <div class="side-info-section">
                <div class="sub-section-container">
                    <div class="sub-section-title">ThermiVa®</div>
                    <div class="sub-section-description">ThermiVa® is a non-surgical, non-invasive treatment that uses radiofrequency energy to rejuvenate and tighten the vaginal tissues and external genitalia. It is primarily used for women who may be experiencing vaginal laxity, urinary incontinence, or sexual dysfunction. Here are some of the potential benefits of ThermiVa treatment:
                        <div class="more-section" @click="showPThermiVa=!showPThermiVa">
                            <span v-if="!showPThermiVa">more</span>
                            <span  v-if="showPThermiVa">less</span> 
                            <img class="down-up-icon"  v-if="!showPThermiVa" src="../assets/icons/chevron-down.svg" alt="">
                            <img class="down-up-icon"  v-if="showPThermiVa" src="../assets/icons/chevron-up.svg" alt="">
                        </div>
                        <div class="expand-section" v-if="showPThermiVa">

                            Improved Vaginal Tightness: ThermiVa can help tighten the vaginal canal, which can lead to increased sexual satisfaction for both the patient and their partner. This tightening effect can also be beneficial for women who have experienced vaginal laxity due to childbirth or aging.<br><br>

                            Enhanced Sensitivity: Many women report increased sensitivity in the treated area after ThermiVa treatment. This heightened sensitivity can lead to improved sexual arousal and pleasure.<br><br>

                            Reduced Urinary Incontinence: ThermiVa can help strengthen the pelvic floor muscles, which may reduce urinary incontinence symptoms. This can be particularly helpful for women who have stress urinary incontinence.<br><br>

                            Non-Invasive and Painless: ThermiVa is a non-surgical procedure, meaning there is no need for incisions or anesthesia. Patients typically experience minimal discomfort during the treatment, and there is little to no downtime afterward.<br><br>

                            Quick Procedure: A ThermiVa session usually takes around 30 minutes to an hour, making it a convenient option for busy individuals.<br><br>

                            No Recovery Time: Since ThermiVa is non-invasive, there is no need for recovery time. Patients can resume their regular activities immediately after the treatment.<br><br>

                            Noticeable Results: Patients often see noticeable improvements after just one treatment session, although a series of sessions may be recommended for optimal results.<br><br>

                            Improved Self-Confidence: Many women report increased self-confidence and improved body image following ThermiVa treatment. This boost in self-esteem can have a positive impact on overall well-being.<br><br>

                            Long-Lasting Results: While individual results can vary, many patients experience long-lasting benefits from ThermiVa, with results potentially lasting up to one to two years or more. Some individuals may choose to undergo periodic maintenance sessions to maintain their results.</div>
                        </div>
                    <div class="sub-section-content"></div>
                </div>
                <div class="sub-section-container">
                    <div class="sub-section-title">PRP Vaginal Rejuvenation</div>
                    <div class="sub-section-description">PRP (Platelet-Rich Plasma) Vaginal Rejuvenation is a medical and cosmetic procedure designed to address various concerns related to vaginal health and function. Platelet-rich plasma is a component of your blood that contains growth factors and other bioactive proteins that can stimulate tissue repair and regeneration. In the context of vaginal rejuvenation, PRP is used to promote the rejuvenation and revitalization of the vaginal tissues.<br><br>Here's how PRP Vaginal Rejuvenation typically works:
                        <div class="more-section" @click="showPRP=!showPRP">
                            <span v-if="!showPRP">more</span>
                            <span  v-if="showPRP">less</span> 
                            <img class="down-up-icon"  v-if="!showPRP" src="../assets/icons/chevron-down.svg" alt="">
                            <img class="down-up-icon"  v-if="showPRP" src="../assets/icons/chevron-up.svg" alt="">
                        </div>
                        <div class="expand-section" v-if="showPRP">
                            Consultation: The process usually begins with a consultation with a healthcare provider or specialist who can assess your specific concerns and determine whether PRP Vaginal Rejuvenation is an appropriate treatment for you.<br><br>Blood Draw: During the procedure, a small amount of your own blood is drawn, similar to a routine blood test.<br><br>Centrifugation: The drawn blood is then processed in a centrifuge to separate the platelet-rich plasma from the rest of the blood components. This concentrated PRP is rich in growth factors.<br><br>Application: The PRP is applied to the vaginal tissues. This can be done in a couple of ways:<br><br>Injections: PRP can be injected into specific areas of the vagina to stimulate collagen production and improve tissue elasticity.<br><br>Topical Application: In some cases, PRP may be applied topically to the vaginal walls.<br><br>The idea behind PRP Vaginal Rejuvenation is that the growth factors in the PRP stimulate cell growth, collagen production, and blood flow, which can lead to several potential benefits, including:<ul>
                                <li>Improved vaginal lubrication</li>
                                <li>Increased sensitivity</li>
                                <li>Enhanced sexual satisfaction</li>
                                <li>Reduced urinary incontinence</li>
                                <li>Improved vaginal tone and tightness</li>
                                <li>Enhanced overall vaginal health</li>
                            </ul></div>
                        </div>
                    <div class="sub-section-content"></div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
// import SideItem from '../components/SideItemComponent.vue'

export default {
    components: {
        // SideItem
    },
    data () {
        return {
            showPThermiVa: false,
            showPRP: false
        }
    },
    methods: {

    },
    mounted() {
        window.scrollTo(0,0);
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';
.page-side-image {
    width: 100%;
}

// .side-section {
//     display: inline-block;
//     width: 40%;
//     vertical-align: top;
// }
// .side-info-section {
//     display: inline-block;
//     width: 60%;
//     text-align: left;
// }

</style>
