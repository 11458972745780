<template>
    <div>
        <div class="main-page-header">MicroNeedling</div>
    </div>
    <div class="bio-page-content">
        <img class="page-image" src="../assets/images/Microneedling.jpg" alt="">
        <div class="page-text">We offer Microneedling with PRP. Platelet Rich Plasma (PRP) is an extract of your own blood that contains three to five times more platelets, growth factors and active proteins for tissue and injury healing. When PRP is placed back into the skin by injection or microneedling, it initiates a localized stem cell response. Over the weeks and months following treatment, this stem cell response recruits collagen-producing cells called fibroblasts. The fibroblasts replace tissue that has been damaged or lost through the aging process with healthy skin that looks refreshed and rejuvenated. The healing properties of the blood components in PRP can be used as a cosmetic treatment for the following: 
            <ul>
                <li>Reducing fine lines and wrinkles</li>
                <li>Tightening and toning skin</li>
                <li>Brightening uneven skin tone</li>
                <li>Mild collagen and volume loss</li>
                <li>Crow’s feet and dark under eye circles</li>
                <li>Large pores/rough texture</li>
                <li>Acne scarring</li>
                <li>Rosacea</li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

</style>
