<template>
    <div>
        <div class="main-page-header">Contact</div>
        <div class="bio-page-content">
            <div class="contact-header">
                <div class="contact-text">We are located in the</div>
                <div class="contact-text">High Tide Dermatology Center</div>
            </div>
            <img class="bio-map" src="../assets/images/BioMap.jpg" alt="">
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

.bio-map {
    width: 70%;
    min-width: 350px;
    padding: 20px;
}
.contact-header {
    padding: 20px;
}
.contact-text {
    font-size: 18px;
    font-weight: 500;
    color: $primary-dark-green;
}



</style>
