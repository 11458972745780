<template>
    <div>
        <div class="main-page-header">Secret RF</div>
    </div>
    <div class="bio-page-content">
        <img class="page-image" src="../assets/images/SecretRF.jpg" alt="">
        <div class="page-text">Secret RF Addresses*: 
            <ul>
                <li>Scars</li>
                <li>Sun damage</li>
                <li>Stretch marks</li>
                <li>Large pores</li>
                <li>General signs of aging</li>
                <li>Skin quality, texture, and appearance</li>
            </ul>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/style.scss';

</style>